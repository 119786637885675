import React from "react";

import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="container">
        <div className={styles.body}>
          <div className={styles.left}>
            <ul className={styles.list}>
              <li className={styles.item}>
                <Link to="/about">О нашей компании</Link>
              </li>
              <li className={styles.item}>
                <Link to="/corporate">Корпоративные услуги</Link>
              </li>
              <li className={styles.item}>
                <Link to="/legal">Юридические услуги</Link>
              </li>
              <li className={styles.item}>
                <Link to="/accounting">Бухгалтерские услуги</Link>
              </li>
              <li className={styles.item}>
                <Link to="/hr">Кадровые услуги</Link>
              </li>
              <li className={styles.item}>
                <Link to="/offers">Акционные предложения</Link>
              </li>
              <li className={styles.item}>
                <Link to="/all">Все наши услуги</Link>
              </li>
            </ul>
            <Link to="/policy" className={styles.policy}>
              Политика обработки персональных данных
            </Link>
          </div>
          <div className={styles.right}>
            <div className={styles.medias}>
              <Link to="https://vk.com/lexleg">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.5442 5.88156C21.6816 5.37375 21.5442 5 20.8833 5H18.6965C18.1395 5 17.8834 5.32094 17.746 5.67844C17.746 5.67844 16.6322 8.64812 15.0579 10.5737C14.5493 11.1303 14.3154 11.3091 14.0369 11.3091C13.8995 11.3091 13.6879 11.1303 13.6879 10.6225V5.88156C13.6879 5.27219 13.532 5 13.0716 5H9.63352C9.28452 5 9.0766 5.28437 9.0766 5.54844C9.0766 6.12531 9.86371 6.25937 9.9454 7.88437V11.4106C9.9454 12.1825 9.81916 12.3247 9.5407 12.3247C8.79814 12.3247 6.99372 9.34281 5.92443 5.93031C5.70909 5.26813 5.49746 5 4.93683 5H2.74999C2.12624 5 2 5.32094 2 5.67844C2 6.31219 2.74256 9.46063 5.45662 13.6206C7.26475 16.4603 9.81173 18 12.1285 18C13.5208 18 13.6916 17.6587 13.6916 17.0697C13.6916 14.3559 13.5654 14.1 14.2634 14.1C14.5864 14.1 15.1433 14.2787 16.4428 15.6478C17.9279 17.2728 18.173 18 19.0046 18H21.1915C21.8152 18 22.1308 17.6587 21.9489 16.9844C21.5331 15.5666 18.7225 12.6497 18.5962 12.4547C18.2732 11.9997 18.366 11.7966 18.5962 11.3903C18.5999 11.3862 21.2694 7.275 21.5442 5.88156Z" />
                </svg>
              </Link>
              <Link to="https://www.instagram.com/lexlegal_rus?igsh=MWQ2bzZkeDF0bHVlOQ==">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    d="M16,21H8c-2.761,0-5-2.239-5-5V8c0-2.761,2.239-5,5-5h8c2.761,0,5,2.239,5,5v8  C21,18.761,18.761,21,16,21z"
                    opacity=".35"
                  ></path>
                  <path d="M12,7c-2.761,0-5,2.239-5,5s2.239,5,5,5s5-2.239,5-5S14.761,7,12,7z M12,15c-1.657,0-3-1.343-3-3s1.343-3,3-3s3,1.343,3,3  S13.657,15,12,15z"></path>
                </svg>
              </Link>
            </div>
            <p className={styles.date}>Пн-Пт, 9:00 - 18:00</p>
            <p className={styles.phone}>+7 (964) 937-27-58</p>
            <p className={styles.author}>©2021 ”ЛексЛегал”</p>
          </div>
        </div>
        <div className={styles.hiddenElements}>
          <p className={styles.hiddenAuthor}>©2021 ”ЛексЛегал”</p>
          <Link to="/policy" className={styles.hiddenAuthor}>
            Политика конфиденциальности
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
